import Chart from "../Chart/Chart";
import "./Charts.css";

function Charts({ datasets, fetchData }) {
  return (
    <main className="charts">
      {datasets.map(({ data, yAxisTitle }, i) => (
        <Chart data={data} title={yAxisTitle} fetchData={fetchData} key={i} />
      ))}
    </main>
  );
}

export default Charts;
