import React, { useEffect, useRef, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import "./Chart.css";
import refreshIcon from "../../img/refreshIcon.svg";
import arrowIcon from "../../img/arrowIcon.svg";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const periods = {
  "1 day": 1,
  "7 days": 7,
  "1 month": 30,
  "3 months": 90,
  "6 months": 180,
  "1 year": 365,
};

function getTwoDigitTime(time) {
  time = time.toString();

  return time.length === 1 ? `0${time}` : time;
}

function getDate(value) {
  const date = new Date(value);
  const year = date.getFullYear();
  const monthName = monthNames[date.getMonth()];
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  let seconds = date.getSeconds().toString();

  month = getTwoDigitTime(month);
  day = getTwoDigitTime(day);
  hours = getTwoDigitTime(hours);
  minutes = getTwoDigitTime(minutes);
  seconds = getTwoDigitTime(seconds);

  return {
    year,
    month,
    monthName,
    day,
    hours,
    minutes,
    seconds,
  };
}

const getPeriodStart = (startTime, endTime, period) => {
  const periodTimeInMs = periods[period] && periods[period] * 24 * 60 * 60 * 1000;

  if (periodTimeInMs === undefined) return startTime;

  const newStart = endTime - periodTimeInMs;

  return newStart > startTime ? newStart : startTime;
};

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    const { year, month, day, hours, minutes, seconds } = getDate(label, true);

    return (
      <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc", borderRadius: "10px" }}>
        <p
          className="label"
          style={{ marginBottom: "5px", color: "#181818" }}
        >{`Time: ${month}/${day}/${year} ${hours}:${minutes}:${seconds}`}</p>
        <p style={{ color: payload[0].color }}>{`${payload[0].name}: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
}

function CustomTick({ x, y, payload: { value }, axis, width, height, visibleTicksCount, index, textAnchor, renderedPeriodInDays }) {
  if (axis === "x") {
    const { year, monthName, day, hours, minutes } = getDate(value);

    return (
      <g transform={`translate(${x},${y})`} textAnchor={textAnchor}>
        <text className="chart__date" dy={(height - 17.5) / 2}>
          <tspan className="chart__day" x={0} dy={(height - 17.5) / 2}>
            {renderedPeriodInDays >= 180 ? `${year}` : `${day} ${monthName}`}
          </tspan>
          <tspan className="chart__hour" x={0} dy={17.5}>
            {renderedPeriodInDays >= 180 ? `${day} ${monthName}` : `${hours}:${minutes}`}
          </tspan>
        </text>
      </g>
    );
  }

  return (
    <g transform={`translate(${width / 2 + 2.5}, ${visibleTicksCount === index + 1 ? 17.5 : index === 0 ? y : y + 6})`} textAnchor={textAnchor}>
      <text className="chart__value">{value}</text>
    </g>
  );
}

function RefreshButton({ fetchData }) {
  const [refresh, setRefresh] = useState(false);
  const refreshRef = useRef(refresh);

  let clazz = "chart__refresh-btn";
  if (refresh) clazz += " chart__refresh-btn--active";

  const onRefresh = () => {
    if (!refreshRef.current) {
      setRefresh(true);
      fetchData();

      setTimeout(() => {
        setRefresh(false);
      }, 1000);
    }
  };

  useEffect(() => {
    refreshRef.current = refresh;
  }, [refresh]);

  return (
    <button className={clazz} onClick={onRefresh}>
      <img className="chart__refresh-btn-icon" src={refreshIcon} alt="↻" />
    </button>
  );
}

function DropdownMenu({ options, option, setOption }) {
  const [showOptions, setShowOptions] = useState(false);

  let clazz = "dropdown-menu";
  if (showOptions) clazz += " dropdown-menu--show";

  const onToggleShowOptions = () => setShowOptions((show) => !show);
  const onHideOptions = () => setShowOptions(false);

  const onSetOption = (option) => {
    setOption(option);
    onHideOptions();
  };

  return (
    <div className={clazz} onBlur={onHideOptions} tabIndex={1}>
      <div className="dropdown-menu__inner" onClick={onToggleShowOptions}>
        <p className="dropdown-menu__selected-option">{option}</p>
        <img className="dropdown-menu__arrow" src={arrowIcon} alt="▼" />
      </div>

      <ul className="dropdown-menu__options">
        {options.map((option, i) => (
          <li className="dropdown-menu__option" onClick={() => onSetOption(option)} key={i}>
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
}

function Chart({ data, title, fetchData }) {
  const periods = ["1 day", "7 days", "1 month", "3 months", "6 months", "1 year", "Max"];
  const [period, setPeriod] = useState(periods[periods.length - 1]);

  const startTime = data[0].timestamp;
  const endTime = data[data.length - 1].timestamp;
  const domain = [getPeriodStart(startTime, endTime, period), endTime];
  const renderedPeriodInDays = (domain[1] - domain[0]) / 1000 / 60 / 60 / 24;

  return (
    <div className="chart">
      <header className="chart__header">
        <h3 className="chart__title">{title}</h3>
        <DropdownMenu option={period} setOption={setPeriod} options={periods} />
        <RefreshButton fetchData={fetchData} />
      </header>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            tick={<CustomTick axis="x" renderedPeriodInDays={renderedPeriodInDays} />}
            height={70}
            tickLine={false}
            textAnchor="start"
            axisLine={{ stroke: "#BABABA" }}
            type="number"
            domain={domain}
            allowDataOverflow
          />
          <YAxis
            tick={<CustomTick axis="y" />}
            axis="y"
            type="number"
            width={90}
            tickLine={false}
            tickMargin={0}
            textAnchor="middle"
            axisLine={{ stroke: "#BABABA" }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone" // This creates a smoother line
            dataKey="value"
            stroke="#6C61FB" // Line color
            strokeWidth={3} // Line thickness
            dot={false} // Remove individual data point markers to make the line appear more continuous
            activeDot={{ r: 4 }} // Active dot size (when hovered)
            animationDuration={1000}
            animationEasing="ease-out"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;
