import "./Header.css";

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img className="logo__icon" src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" />
        <h1 className="logo__title">Soarchain Analytics</h1>
      </div>
    </header>
  );
}

export default Header;
