import React, { useState, useEffect, useCallback } from "react";
import Header from "./components/Header/Header";
import Error from "./components/Error/Error";
import Charts from "./components/Charts/Charts";
import "./App.css";

const parseTimestamp = (date) => {
  const pattern = /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/;
  return Date.parse(new Date(date.replace(pattern, "$3-$1-$2T$4:$5:$6")));
};

function App() {
  const [datasets, setDatasets] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch("/data");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      const keysForYAxis = Object.keys(data[0]).filter((key) => key !== "Timestamp");
      const preparedDatasets = keysForYAxis.map((key) => ({
        yAxisTitle: key,
        data: data.map((item) => ({
          timestamp: parseTimestamp(item["Timestamp"]),
          value: item[key],
        })),
      }));

      setDatasets(preparedDatasets);
      setError(null);
    } catch (error) {
      setError("Error connecting to backend...");
      setDatasets([]);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <Header />
      {error ? <Error msg={error} /> : <Charts datasets={datasets} fetchData={fetchData} />}
    </div>
  );
}

export default App;
